import { Grid, Paper } from "@mui/material";

import { construct } from "radash";

import { json, redirect } from "react-router-dom";

import { post } from "../apiClient";
import AudienceForm from "../features/audiences/AudienceForm";
import createFormErrors from "../utils/createFormErrors";

export async function action({ request }) {
  const formData = await request.formData();
  const payload = construct(Object.fromEntries(formData));
  const segmentIds =
    formData.get("segment_ids")?.length > 0
      ? formData.get("segment_ids").split(",")
      : [];

  try {
    const audience = await post(
      "audience",
      "/v1/audiences",
      {
        ...payload,
        segment_ids: segmentIds,
      },
      { signal: request.signal }
    );

    return redirect(`/audiences/${audience.id}`);
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422:
        return json(
          { errors: createFormErrors(error.details.errors) },
          { status: 422 }
        );
      default:
        throw error;
    }
  }
}

export default function NewAudience() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <AudienceForm action="/audiences/new" />
        </Paper>
      </Grid>
    </Grid>
  );
}
