const shouldPreventKeyPress = (key, currentValue, maxValue) => {
  if (isNaN(key) || key === " ") return true;

  return (
    !!key.match(/^[0-9]$/) &&
    parseInt([currentValue, key].join(""), 10) > maxValue
  );
};

export const handleKeyPress = (maxValue) => (event) => {
  shouldPreventKeyPress(event.key, event.target.value, maxValue) &&
    event.preventDefault();
};
