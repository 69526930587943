import {
  Grid,
} from "@mui/material";

import OptimizationBox from "./OptimizationBox";
import OptimizationBoxItemsList from "./OptimizationBoxItemsList";

import { buildTargetingRuleInfoItems } from "../utils/optimizationDetailsUtils";

const AudienceDetail = ({ details: rules }) => {
  if (rules.length === 0) return;

  const audienceRules = rules.filter(({ value }) => value.type === "audience");
  const segmentRules = rules.filter(({ value }) => value.type === "segment");

  return (
    <OptimizationBox panelId={"audience"} title={"Audience"}>
      <Grid container direction="column">
      {audienceRules.length > 0 && (
        <Grid item>
          <OptimizationBoxItemsList
            title={"3rd Party & Custom Audiences"}
            items={buildTargetingRuleInfoItems(audienceRules)} />
        </Grid>
      )}
      {segmentRules.length > 0 && (
        <Grid item>
          <OptimizationBoxItemsList
            title={"3rd Party & Custom Segments"}
            items={buildTargetingRuleInfoItems(segmentRules)} />
        </Grid>
      )}
      </Grid>
    </OptimizationBox>
  );
}

export default AudienceDetail;
