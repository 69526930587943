import { listify, construct } from "radash";

import PacingDetail from "./components/PacingDetail";
import BidDetail from "./components/BidDetail";
import AudienceDetail from "./components/AudienceDetail";
import GeographyDetail from "./components/GeographyDetail";
import CreativeDetail from "./components/CreativeDetail";
import InventoryDetail from "./components/InventoryDetail";

import { useOptimizationChanges } from "./OptimizationsContext";

const OPTMIZATION_COMPONENTS = {
  pacing: (props) => <PacingDetail details={props || {}} key={"pacing"} />,
  bid: (props) => <BidDetail details={props || {}} key={"bid"} />,
  audience: (props) => <AudienceDetail details={props || []} key={"audience"} />,
  geography: (props) => <GeographyDetail details={props || []} key={"geography"} />,
  creative: (props) => <CreativeDetail details={props || {}} key={"creative"} />,
  inventory: (props) => <InventoryDetail details={props || {}} key={"inventory"} />
}

const OptimizationDetails = () => {
  let optimizations = useOptimizationChanges();
  optimizations = construct(optimizations)

  return (<>{listify(OPTMIZATION_COMPONENTS, (name, componentFn) => componentFn(optimizations && optimizations[name]))}</>);
}

export default OptimizationDetails;
